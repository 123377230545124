export default {
  methods: {
    // 单条聊天消息的主模块儿
    elPackage(children) {
      const { message } = this;
      return (
        <div
          class={[
            "imelink-message",
            this.isSelfMessage(message.fromUser.id) && "imelink-message--self",
            this.message.originalSenderId && "imelink-message--origin",
          ]}
        >
          {this.elAvatar()}
          {children}
        </div>
      );
    },
    // 用户头像
    elAvatar() {
      const { message } = this;
      return (
        <div class="imelink-message__avatar">
          <avatar
            src={this.formartImageUrl(message.fromUser.avatar)}
            nativeOnClick={this._handleAvatarClick}
            size="36px"
          />
        </div>
      );
    },
    // 右键菜单包裹的主体
    elContent(children, options) {
      options = {
        ...{ bubble: true },
        ...options,
      };
      const tag = this.$options._componentTag;
      let meChatWarning = "";
      if (this.isSelfMessage(this.message.fromUser.id)) {
        meChatWarning = this.message.meChatWarning
          ? "imelink-message__content--meChatWarn"
          : "";
      }
      return [
        <div class="imelink-message__contentBox">
          <div class="imelink-message__contentInnerBox">
            {this.isSelfMessage(this.message.fromUser.id) && this.elStatus()}
            <contextmenu
              ref="content"
              menu={this.contextmenu}
              on={{
                show: this._handleContextmenuShow,
              }}
              class={[
                "imelink-message__content",
                tag,
                meChatWarning,
                { "imelink-message__content--bubble": options.bubble },
                [
                  this.isSendGoing() && `${tag}--going`,
                  this.isSendFailed() && `${tag}--failed`,
                  this.isSendSucceed() && `${tag}--succeed`,
                ],
              ]}
              nativeOn={{ click: this._commonHandleContentClick }}
            >
              {options.bubble}
              {children}
            </contextmenu>
          </div>
          {this.elChatPermission()}
        </div>,
        this.message.originalSenderId &&
          this.message.originalSenderId !== this.message.fromUser.id && (
            <div class="imelink-message__origin">
              <i class="el-icon--tran" />
              &nbsp;
              {this.message.originalSender}
            </div>
          ),
        this.message.fromUser.id != 2 ? (
          <div class="imelink-message__fromuser">
            <span class="imelink-message__fromname">
              {this.message.fromUser.displayName &&
              this.message.fromUser.displayName.length > 12
                ? this.message.fromUser.displayName.slice(0, 12) + "..."
                : this.message.fromUser.displayName}
            </span>
            <span class="imelink-message__fromtime">
              {this._parseTime(this.message.sendTime)}
            </span>
          </div>
        ) : (
          <div class="imelink-message__fromuser">
            <span class="imelink-message__fromtime">
              {this._simpleParseTime(this.message.sendTime)}
            </span>
          </div>
        ),
      ];
    },
    // 对方设置了仅好友可以聊天后，被拦截后的展示
    elChatPermission() {
      // 这里判断一下这条信息必须是自己本人发出的信息，并且是被拦截后的信息
      let legalStatus =
        this.$store.getters.userInfo.id == this.message.fromUser.id &&
        this.message.notAllowChat;
      return (
        legalStatus && (
          <div class={["imelink-message__allowChatTips"]}>
            {this.message.notAllowTips}
          </div>
        )
      );
    },
    // 消息发送状态图标模块
    elStatus() {
      return this.elStatusPackage([
        this._elStatusFailedInner(),
        this._elStatusGoingInner(),
        this._elStatusMechatWarnInner(),
      ]);
    },
    // 状态图标外层盒子
    elStatusPackage(children) {
      return <div class={["imelink-message__status"]}>{children}</div>;
    },
    elStatusFailed() {
      return this.elStatusPackage(this._elStatusFailedInner());
    },
    elStatusGoing() {
      return this.elStatusPackage(this._elStatusGoingInner());
    },
    _elStatusMechatWarnInner() {
      return (
        <i
          v-show={this.isMechatWarning()}
          class="icon-remind iconfont_Me"
          style="color:#D86B1D;font-size:18px;position: absolute;top:50%;left:50%"
        />
      );
    },
    _elStatusFailedInner() {
      const { control, message } = this;
      return this.message.notAllowChat ? (
        <i v-show={this.isSendFailed()} class="el-icon-warning" />
      ) : (
        <i
          v-show={this.isSendFailed()}
          tooltip={this.$t("resendMsg")}
          style="zIndex: 2"
          class="imelink-message__status--warning el-icon-warning"
          onClick={() => {
            control._resendMessage(message);
          }}
        />
      );
    },
    _elStatusGoingInner() {
      return (
        <i
          v-show={this.isSendGoing()}
          class="imelink-message__status--loading el-icon-loading"
        />
      );
    },
    // eslint-disable-next-line no-unused-vars
    elTransMSG(item, options) {
      options = {
        ...{
          bubble: true,
        },
        ...options,
      };
      return (
        <div
          class="imelink-message-voice__trans"
          nativeOn={{
            click: this._commonHandleContentClick,
          }}
        >
          <div class={["imelink-message-voice__trans__translateMsgs"]}>
            {" "}
            {item.translateMsg}
          </div>
          {item.showBtns ? (
            <div class="imelink-message-voice__trans__translateMsgs__btns">
              <i class="el-icon-close icon" />
              <i class="el-icon-check icon" />
            </div>
          ) : (
            ""
          )}
        </div>
      );
    },
  },
};
