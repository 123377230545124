<script>
import basicMixin from "./mixins/basic";
import { isString } from "utils/validate";
import Jquery from "jquery";
import Typewriter from "@/newVersion/components/typewriter";
let selectionText;
export default {
  inject: ["control"],
  name: "MessageText",
  mixins: [basicMixin],
  data() {
    return {
      replaceInit: false,
      translateEdit: false,
      curDom: null,
      keyword: "",
      user: {},
      jumpTimer: null,
    };
  },
  created() {
    this.user = this.$store.getters.userInfo;
    this.setContextMenu();
  },
  components: {
    Typewriter,
  },
  mounted() {
    this.handleMessage();
    this.$nextTick(() => {
      if (this.message.lightMsg && this.$el.offsetTop - 50) {
        if (this.jumpTimer) {
          clearTimeout(this.jumpTimer);
        }
        this.jumpTimer = setTimeout(() => {
          this.jumpMessage();
        }, 500);
      }
    });
  },
  props: {
    languageEnvironment: {
      type: String,
      default: "1",
    },
  },
  methods: {
    // 跳到搜索位置
    jumpMessage() {
      if (!this.message) {
        return;
      }
      try {
        this.control.$refs["messageView"].$refs[
          `${this.message.sendTime + this.messageIndex}`
        ][0].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } catch (error) {
        console.log(error);
      }
    },
    // 装填右键菜单操作选项
    setContextMenu() {
      if (
        typeof this.message.text !== "string" ||
        this.message.subType === "link"
      ) {
        // this.contextmenu = [];
      } else if (this.message.subType !== "link") {
        this.contextmenu.push({
          icon: "el-icon-document-copy",
          tooltip: this.$t("copy"),
          click: () => {
            const el = document.createElement("textarea");
            el.style.position = "absolute";
            el.style.zIndex = "-10";
            el.style.top = "0";
            el.style.left = "0";
            el.style.opacity = "0";
            el.innerHTML = (selectionText || this.message.text).replace(
              /<[^>]*>|<\/[^>]*>/gm,
              ""
            );
            document.body.appendChild(el);
            el.select();
            document.execCommand("Copy");
            el.remove();
          },
        });
      }
      // 如果是解密后的消息
      if (this.message.isDecrypted && !this.message.isSelfMeassge) {
        this.contextmenu = [];
      }
    },
    // 判断是否被@
    isCheckAt(message) {
      if (message) {
        return message.includes("@" + this.control.user.displayName);
      } else {
        return false;
      }
    },
    // 给@的数据加上样式
    atCheck(message) {
      return message.replaceAll(
        `@${this.control.user.displayName}`,
        `<span style="color:#52BD68">@${this.control.user.displayName}</span>`
      );
    },
    // 每次创建都装载一下数据
    handleMessage() {
      if (this.replaceInit == false) {
        // 抓取超链接
        if (isString(this.message.text)) {
          try {
            this.message.text = this.control.filterEmojiImage(
              this.message.text,
              ""
            );
          } catch (err) {}
          this.message.text = this.message.text;
          // 抓取超链接
          this.message.hpLink = this.hyperlinksCheck(this.message.text);
          // 判断是否包含邮箱
          const emailCheck = this.emailsCheck(this.message);
          if (emailCheck.isEmailStr) {
            this.message.hpLink = emailCheck.text;
          }
          // 判断是否包含手机号
          const phoneCheck = this.phonesCheck(this.message);
          if (phoneCheck.isPhoneStr) {
            this.message.hpLink = phoneCheck.text;
          }
        }
        // 抓取@消息
        if (this.isCheckAt(this.message.text)) {
          this.message.hpLink = this.atCheck(this.message.text);
        }
        this.replaceInit = true;
      }
      // 搜索消息定位到聊天窗口高亮
      let keyword = localStorage.getItem("catchWord");
      if (this.message.lightMsg && keyword) {
        keyword = keyword.trim();
        this.message.text = this.message.text.replace(
          keyword,
          `<font color="red">${keyword}</font>`
        );
        this.message.hpLink = this.message.hpLink.replace(
          keyword,
          `<font color="red">${keyword}</font>`
        );
        if (this.message.msgs && this.message.msgs.msgs) {
          this.message.msgs.msgs.map((el) => {
            try {
              el.translateMsg = el.translateMsg.replace(
                keyword,
                `<font color="red">${keyword}</font>`
              );
            } catch (error) {
              console.log(el.translateMsg);
            }
          });
        }
      }
    },
    handleContentClick(e) {
      let parent,
        child,
        classList = null;

      // 必须是点击箭头才能触发
      try {
        const index = Array.from(e.target.classList).findIndex(
          (i) => i == "imelink-message-text_arrow"
        );
        if (index == -1) {
          return;
        }
      } catch (error) {}
      if (e.target.classList[1] === "trans_list") {
        parent = Jquery(e.target);
      } else {
        parent = Jquery(e.target).parents(".trans_list");
      }
      child = parent.find(".imelink-message-text__translateMsg");
      if (child.length) {
        classList = child[0].classList;
        if (classList.contains("showAll") && !classList.contains("hasMore")) {
          return;
        }
        if (!classList.contains("showAll") && classList.contains("hasMore")) {
          child.addClass("showAll");
          parent.addClass("all");
        } else if (!classList.contains("edit") && !classList.contains("self")) {
          child.removeClass("showAll");
          parent.removeClass("all");
        }
      }
    },
    textExists() {
      const { text } = this.message;
      if (isString(text)) {
        return !!text.replace(/\[.*?\]/g, "");
      } else {
        return false;
      }
    },
    getTranslateUser(id) {
      if (id) {
        const groupInfo = this.control.getCurrentGroupInfo();
        if (groupInfo)
          return groupInfo.members.find((item) => item.id == id) || {};
      }
      return {};
    },
    _handleTranslateEdit() {
      this.translateEdit = true;
      if (!this.message.translate) {
        this.message.translate = this.message.text;
      }
    },
    _handleTranslateEditClose() {
      this.$refs.translateTextarea.innerHTML = (
        this.message.translate || this.message.text
      ).replace(/\n/g, "<br/>");
      this.translateEdit = false;
    },
    _handleTranslateEditSend() {
      this.message.translateUserId = this.control.user.id;
      //console.log(this.$refs.translateTextarea.innerText);
      this.message.translate = this.$refs.translateTextarea.innerHTML
        .replace(/<div>/gi, "\n")
        .replace(/<\/div>/gi, "")
        .replace(/<br>/gi, "\n")
        .replace(/<br\/>/gi, "\n");
      //translate.replace(/<br>/g, "<br><hr>")
      this.control.$emit(
        "translate-edit",
        this.message,
        this.control.currentContact
      );
      this._handleTranslateEditClose();
    },
    tryalignTransText(event) {
      let index = Jquery(event.target).attr("data-index");
      let language = Jquery(event.target).attr("data-language");
      this.message.msgs.msgs[index].updataisGoing = true;
      this.updataTranslageMsgGoing(index);
      this.emitTranslateChange({
        msgText: "",
        messageId: this.message.id,
        language,
        multipleTranslate: "2",
        mimeType: "text",
        index,
      });
    },
    cancle() {
      if (this.curDom) {
        this.curDom.find(".imelink-message-text__editBox").hide();
        this.curDom.find(".imelink-message-text__translateMsg").show();
      }
    },
    updateTrans() {
      let newText = this.curDom
        .find(".imelink-message-text__editBox--textarea")
        .val();
      let language = this.curDom.attr("data-language");
      let index = this.curDom.attr("data-index");

      this.emitTranslateChange({
        msgText: newText,
        messageId: this.message.id,
        language,
        multipleTranslate: "3",
        mimeType: "text",
        index,
      });
      selectionText = "";
      this.cancle();
    },
    removeMenuExit() {
      selectionText = "";
      this.contextmenu.some((menu, index) => {
        if (menu.icon === "el-icon--editw") {
          this.contextmenu.splice(index, 1);
        }
      });
    },
    addMenuExit(index, event) {
      this.curDom = Jquery(event.target).parents(".trans_list");
      selectionText = this.curDom
        .find(".imelink-message-text__editBox--textarea")
        .val();
      if (!this.contextmenu.some((menu) => menu.icon === "el-icon--editw")) {
        this.contextmenu.push({
          icon: "el-icon--editw",
          click: () => {
            event.target.classList.add("imelink-message-text__translate--edit");
            this.curDom.find(".imelink-message-text__editBox").show();
            this.curDom.find(".imelink-message-text__translateMsg").hide();
            this.curDom
              .siblings()
              .find(".imelink-message-text__editBox")
              .hide();
            this.curDom
              .siblings()
              .find(".imelink-message-text__translateMsg")
              .show();
          },
        });
      }
    },
    handlerResetHeight(index) {
      let target = "textarea_" + index;
      const dom = this.$refs[target];
      dom.style.height = dom.scrollHeight + "px";
    },
    normalChat() {
      const { translate, msgs } = this.message;
      let translist = [];
      let selfTransmsg = {};
      if (msgs && msgs.msgs) {
        const sourceLanguage = this.message.msgs.sourceLanguage;
        msgs.msgs.map((item) => {
          if (
            item.language !== sourceLanguage &&
            item.language == this.languageEnvironment
          ) {
            translist = [item];
          }
        });

        if (Object.keys(selfTransmsg).length) translist.unshift(selfTransmsg);
      }
      return this.elPackage([
        this.elContent([
          isString(this.message.hpLink)
            ? [
                <p
                  ref="text"
                  style="padding:8px 12px"
                  domProps={{
                    innerHTML: this.$emoReplaceToImage(
                      this.message.hpLink
                    ).replace(/\r\n|\n/gi, "<br/>"),
                  }}
                  onMousedown={this.removeMenuExit}
                />,
              ]
            : [
                <p
                  ref="text"
                  style="padding:8px 12px"
                  onMousedown={this.removeMenuExit}
                >
                  {this.message.text}
                </p>,
              ],

          !this.message.disshowMsgs &&
            translist.map((item, index) => {
              if (
                item.language !== this.message.msgs.sourceLanguage &&
                item.translateMsg &&
                this.$emoReplaceToImage(item.translateMsg)
              ) {
                const multiline =
                  !item.self &&
                  item.translateMsg.length > (item.language !== 8 ? 50 : 40);
                return [
                  <div
                    class={[
                      "imelink-message-text__translate trans_list",
                      {
                        trans: multiline,
                      },
                      {
                        all:
                          (index == 0 || item.translator_id == this.user.id) &&
                          multiline,
                      },
                    ]}
                    data-language={item.language}
                    data-index={index}
                    data-self={item.self || item.translator_id == this.user.id}
                  >
                    {item.updataisGoing && <i class="el-icon-loading" />}
                    <div
                      class={[
                        "imelink-message-text__translateMsg",
                        {
                          hasMore: multiline,
                        },
                        { self: item.self },
                        {
                          showAll:
                            index == 0 || item.translator_id == this.user.id,
                        },
                      ]}
                      domProps={{
                        innerHTML: this.$emoReplaceToImage(
                          item.translateMsg.replace(/\n/g, "<br>")
                        ),
                      }}
                      contenteditable={item.contenteditableTranslage}
                      onMousedown={this.addMenuExit.bind(this, index)}
                    />
                    {item.translator_id != 2 && (
                      <avatar
                        class="imelink-message-translate__avatar"
                        size="22px"
                        src={
                          this.getAuthorAvatar(item.translator_id.toString()) ||
                          ""
                        }
                      />
                    )}
                    <div
                      class="imelink-message-text__editBox"
                      style=" display: none;"
                    >
                      <textarea
                        class="imelink-message-text__editBox--textarea"
                        autofocus
                        ref={`textarea_${index}`}
                        onFocus={this.handlerResetHeight.bind(this, index)}
                      >
                        {this.$emoReplaceToImage(
                          item.translateMsg.replace(/<\/?.+?\/?>/g, "")
                        )}
                      </textarea>
                      <div class="imelink-message-text__translateMsgs__btns">
                        <i class="el-icon-close icon" onClick={this.cancle} />
                        <i
                          class="el-icon-check icon"
                          onClick={this.updateTrans}
                        />
                      </div>
                    </div>
                    <div class="imelink-message-text_arrow"></div>
                  </div>,
                ];
              } else if (item.language !== this.message.msgs.sourceLanguage) {
                return (
                  <div
                    class="imelink-message-text__error"
                    data-language={item.language}
                    data-index={index}
                    onClick={this.tryalignTransText}
                  >
                    <i
                      data-language={item.language}
                      data-index={index}
                      class={[
                        item.updataisGoing
                          ? "el-icon-loading"
                          : "el-icon-warning",
                        "errorIcon",
                      ]}
                    />
                  </div>
                );
              }
            }),
          !this.message.msgs && translate && (
            <div
              domProps={{
                innerHTML: this.$emoReplaceToImage(
                  translate.replace(/\n/g, "<br>")
                ),
              }}
              ref="translateTextarea"
              contenteditable={this.translateEdit}
              class={[
                "imelink-message-text__translate imelink-message-text__oldTranslate",
                this.translateEdit && "imelink-message-text__translate--edit",
              ]}
            />
          ),
        ]),
      ]);
    },
    robotChat() {
      const showTypewriter =
        this.messageIndex != 0 &&
        this.message.fromUser.id !== this.$store.getters.userInfo.id &&
        this.control.getCurrentMessage().length - 1 == this.messageIndex &&
        (this.message.fromUser.isFromSocket || this.message.text == "");
      if (this.message.text.indexOf("’") != -1) {
        this.message.text = this.message.text.replaceAll("’", "'");
      }
      return this.elPackage([
        this.elContent([
          showTypewriter
            ? [
                <p
                  ref="text"
                  style="padding: 8px 12px;"
                  onMousedown={this.removeMenuExit}
                >
                  <Typewriter text={this.message.text}></Typewriter>
                </p>,
              ]
            : [
                <p
                  ref="text"
                  style="padding: 8px 12px;white-space: pre-line"
                  onMousedown={this.removeMenuExit}
                >
                  {this.message.text}
                </p>,
              ],
        ]),
      ]);
    },
  },

  render() {
    const isRobotChat = !!this.control.currentContact.isRobotChat;
    return isRobotChat ? this.robotChat() : this.normalChat();
  },
};
</script>

<style lang="stylus">
@import '~styles/utils/index';

.errorIcon {
  font-size: 20px;
  margin-left: 0.5rem;

  &.el-icon-warning {
    color: red;
  }

  &.el-icon-loading {
    color: #999999;
  }
}

.imelink-message-text {
  padding: 0 !important;
  display: flex;
  flex-flow: column;

  &.imelink-message__content {
    font-size: 12px;
  }

  +e(error) {
    cursor: pointer;
  }

  +e(editBox) {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-end;

    +m(textarea) {
      width: 100%;
      display: inline-block;
      line-height: 22px;
      min-height: 100px;
      // max-height: 200px;
      border: 0;
      padding: 0.25rem;
      // overflow-x: hidden;
      transition: all 200ms linear;
      // height -webkit-fill-available
      overflow-y: scroll;
      scrollbar-light();
    }
  }

  +e(oldTranslate) {
    background: #c6edcc;
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }

  +e(translate) {
    &.trans {
      &::after {
        font-family: 'icons' !important;
        content: '\e694';
        // position: absolute;
        // margin-left: 5px;
        margin-top: 5px;
        // padding-left 5px;
        // right: -1px;
        // top: 5px;
        // border: 1px solid #e5e5e5;
        text-align: center;
        border-radius: 20px;
        background: #ffffff;
        line-height: 15px;
        color: #999999;
        font-size: 16px;
        cursor: pointer;
      }
    }

    &.all {
      &::after {
        font-family: 'icons' !important;
        content: '\e80a' !important;
      }
    }

    &.trans_list {
      display: flex;
      align-items: flex-start;
      background: none;
      border-top: 1px solid #ffffff;
      width: 100%;
      position: relative;

      .imelink-message-text__translateMsg {
        flex: 1;
        // padding: 0.5rem 12px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        // display: flex;
        flex-flow: row;
        cursor: default;
        max-height: 24px;

        &.showAll {
          white-space: normal;
          height: auto;
          display: flex;
          word-break: break-word;
          display: inline;
          max-height: none !important;

          img {
            float: left;
          }

          &.hasMore {
          }
        }

        &.edit {
          background: #ffffff;
        }

        +e(btns) {
          font-size: 15px;
          display: flex;
          flex-flow: row;
          margin-top: 0.5rem;

          i {
            padding: 0.1rem 0.6rem;
            background: #ffffff;
            margin: 0 0.5rem 0 0;
            border-radius: 3px;
            cursor: pointer;

            &.el-icon-check {
              background: #33cc66;
              color: #ffffff;
            }

            &.el-icon-close {
              background: #9da2a6;
              color: #ffffff;
            }
          }
        }
      }

      .imelink-message-text_arrow {
        width: 15px;
        height: 20px;
        position: absolute;
        top: 8px;
        right: 12px;
        z-index: 10;
        cursor: pointer;
      }
      // border-radius: 5px;
      &:last-child {
        // border 0
      }

      .el-icon-loading {
        margin-right: 0.3rem;
        font-size: 18px;
      }
    }
  }

  +m(editBtns) {
    position: absolute;
    right: 0;
    bottom: 15px;

    .icon {
      margin: 0 0.5rem 0;
      padding: 0 0.5rem;
      border-radius: 3px;
      color: #ffffff;
      font-size: 18px;

      &.el-icon-close {
        background: #999999;
      }

      &.el-icon-check {
        background: #33cc66;
      }
    }
  }
}

+b(imelink-message-text) {
  display: inline-block;
  word-wrap: break-word;
  word-break: break-word;
  cursor: text !important;
  line-height: 22px !important;

  img {
    vertical-align: middle;
    // height: 22px;
    position: relative;
    top: -2px;
  }

  +e(translateMsgs) {
    flex: 1;
    // padding: 0.5rem 12px;
    width: 100%;
    overflow: hidden;
    // text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    display: flex;
    flex-flow: row;
    cursor: default;
    padding: 0 5px;

    &.showAll {
      white-space: normal;
      height: auto;
      display: flex;
      word-break: break-all;
      display: inline;

      &::after {
        top: auto;
        bottom: 3px;
        font-family: 'icons' !important;
        content: '\e80a';
      }
    }

    &.edit {
      background: #ffffff;
    }

    &::after {
      font-family: 'icons' !important;
      content: '\e694';
      position: absolute;
      margin-left: 0.9rem;
      right: 1px;
      top: 14px;
      // border: 1px solid #e5e5e5;
      text-align: center;
      border-radius: 20px;
      background: #ffffff;
      line-height: 15px;
      color: #999999;
      font-size: 20px;
      z-index: 1;
    }

    +e(btns) {
      font-size: 15px;
      display: flex;
      flex-flow: row;
      margin-top: 0.5rem;

      i {
        padding: 0.1rem 0.6rem;
        background: #ffffff;
        margin: 0 0.5rem 0 0;
        border-radius: 3px;
        cursor: pointer;

        &.el-icon-check {
          background: #33cc66;
          color: #ffffff;
        }

        &.el-icon-close {
          background: #9da2a6;
          color: #ffffff;
        }
      }
    }
  }
}

.imelink-message-text {
  .imelink-message-text__translate {
    padding: 8px 12px;
    box-sizing: border-box;
    outline: none;
  }

  hr {
    border: 0;
    height: 1px;
    background: #90cf99;
    margin: 10px 0;

    &:last-child {
      display: none;
    }
  }

  .imelink-message-text__translate-edit {
    left: auto;
    right: -30px;
    top: 8px;
    position: absolute;
    color: #52bd68;
    font-size: 16px;
    cursor: pointer;
    padding: 4px;
    z-index: 1;
  }
}

.imelink-message-text__translate.imelink-message-text__translate--edit {
  border: 1px solid #4ece6c;
  background: #fff;
  min-width: 120px;
}

.imelink-message-text__translate-action {
  position: absolute;
  bottom: -34px;
  right: 0;
  z-index: 1;
}

.imelink-message--self {
  .imelink-message-text__translate {
    border-top-color: #fff;
    background: #e6f5e9; // #c6edcc;
  }

  .imelink-message-text__translate-edit {
    right: auto;
    left: -30px;
    top: 8px;
  }

  .imelink-message-translate__avatar {
    right: auto;
    left: -30px;
  }
}

.imelink-message-translate__avatar {
  position: absolute;
  right: -30px;

  // bottom: 3px;
  .me-avatar__error {
    font-size: 12px;
  }
}
</style>
