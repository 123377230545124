<template>
  <span class="typing">
    <span
      :style="{ 'white-space': 'pre-line' }"
      v-html="renderCodeBlock(displayText)"
    ></span
    ><span
      v-if="isTyping"
      class="cursor"
      :class="{ blink: isCursorVisible }"
    ></span>
  </span>
</template>

<script>
import Bus from "@/utils/bus.js";
export default {
  name: "Typewriter",
  data() {
    return {
      displayText: "",
      currentIndex: 0,
      isTyping: false,
      isCursorVisible: true,
      timer_1: null,
      timer_2: null,
    };
  },
  destroyed() {
    this.clearTimer();
  },
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  watch: {
    text: {
      handler(val, old) {
        if (val != old) {
          this.clearTimer();
          this.toggleCursor();
          this.typeText();
          this.isTyping = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    renderCodeBlock(html) {
      return html;
    },
    typeText() {
      if (this.currentIndex === this.text.length && this.text.length != 0) {
        this.isTyping = false;
        this.$store.commit("setSubmitDisabled", false);
        return;
      }
      if (this.text != "") {
        Bus.$emit("mechatScrollToBottom");
      }
      this.$store.commit("setSubmitDisabled", true);
      this.displayText += this.text.charAt(this.currentIndex);
      this.currentIndex++;
      const typingSpeed = Math.floor(Math.random() * 20) + 20;
      if (this.timer_1) {
        clearTimeout(this.timer_1);
      }
      this.timer_1 = setTimeout(() => {
        this.typeText();
      }, typingSpeed);
    },
    toggleCursor() {
      this.isCursorVisible = !this.isCursorVisible;
      if (this.timer_2) {
        clearTimeout(this.timer_2);
      }
      this.timer_2 = setTimeout(() => {
        this.toggleCursor();
      }, 500);
    },
    clearTimer() {
      if (this.timer_1) {
        clearTimeout(this.timer_1);
      }
      if (this.timer_2) {
        clearTimeout(this.timer_2);
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.typing
  width: 100%;
  display: block;
  overflow: hidden;
  line-height: 20px;
.cursor
  display: inline-block;
  vertical-align: bottom;
  width: 3px;
  height: 16px;
  margin-left: 1px;
  background-color: #4D4D4D;
  opacity: 1;
  transition: opacity 0.5s ease-out;
.blink
  animation: cursor-blink 1s infinite;
@keyframes cursor-blink
  from { opacity: 1 }
  to { opacity: 0 }
</style>
