import elementMixin from "./element";
import { formatTime, parseTime } from "@/utils";
export default {
  inject: ["control"],
  mixins: [elementMixin],
  data() {
    this.allowStatus = ["send_succeed", "send_failed", "send_going"];
    return {
      bindClick: null,
      // 右键菜单
      contextmenu: [],
    };
  },
  mounted() {
    if (this.control.currentContact.id != 2) {
      this.addRollback();
    }
    this.addDeleteButton();
    this.addRelay();
    this.addEmail();
  },
  props: {
    messageIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isTranslateUser() {
      const group = this.control.getCurrentGroupInfo();
      if (group) {
        return group.translate.proxyUserId == this.control.user.id;
      }
      return false;
    },
    message() {
      try {
        return this.control.getCurrentMessage()[this.messageIndex];
      } catch (error) {
        console.log("获取聊天消息信息失败");
      }
    },
  },
  methods: {
    // 添加通用按钮
    addDeleteButton() {
      this.contextmenu.push({
        icon: "iconfont_Me icon-delet",
        name: "delete",
        tooltip: this.$t("delete"),
        click: this.deleteMessage,
      });
    },
    // 判断是否需要添加撤回
    addRollback() {
      const fromId = this.message.fromUser.isGroup
        ? this.message.fromUser.selfId
        : this.message.fromUser.id;
      if (
        this.control.user.id == fromId ||
        this.control.user.id == this.message.fromUser.selfId
      ) {
        this.contextmenu.push({
          icon: "el-icon--rollback",
          name: "recall",
          tooltip: this.$t("recall"),
          click: this.handleRecall,
        });
      }
    },
    // 判断是否不需要添加分享功能
    addRelay() {
      // 加密消息、机器人消息不能分享
      if (
        this.message.encryptMessage != 1 &&
        !this.message.isDecrypted &&
        this.control.currentContact.id != 2
      ) {
        this.contextmenu.push(
          {
            icon: "el-icon--transmit",
            name: "relay",
            tooltip: this.$t("forward"),
            click: this.handleRelay,
          },
          {
            icon: "iconfont_Me icon-a-zu10130",
            tooltip: this.$t("quote"),
            click: this.quote,
          }
        );
      }
    },
    // 添加发送邮件按钮
    addEmail() {
      if (this.message.type == "text" && typeof this.message.text == "string") {
        this.contextmenu.push({
          icon: "iconfont_Me icon-a-Group34998",
          tooltip: this.$t("sendEmail"),
          click: this.handleEmail,
        });
      }
    },
    // 删除消息
    deleteMessage() {
      this.control.$emit(
        "delete-self-message",
        this.message,
        this.control.currentContact
      );
    },
    getAuthorAvatar(id) {
      let url = "";
      if (this.control.currentContact.isGroup) {
        this.control.getCurrentGroupInfo().members.some((user) => {
          if (user.id == id) url = user.avatar;
        });
      } else {
        // 处理单聊获取翻译者头像
        if (this.control.currentContact.id === id) {
          url = this.control.currentContact.avatar;
        } else {
          url = this.$store.getters.userInfo.avatar;
        }
      }
      return url;
    },
    sortSelfTranslate(msgs, translate) {
      let temparray = [];

      if (msgs && translate) {
        msgs.map((item) => {
          if (item.translateMsg === translate) {
            temparray.push(item);
          }
        });
        msgs.map((item, index) => {
          if (item === temparray[0]) msgs.splice(index, 1);
        });
        msgs = temparray.concat(msgs);
      }
      return msgs;
    },
    // 更新数据
    update(data) {
      if (data.id) delete data.id;
      if (data.status && !this.allowStatus.includes(data.status)) {
        throw `[${data.status}] property does not support`;
      }
      let dataChanged = false;
      try {
        const { id } = this.control.currentContact;
        const message = this.control.messageAll[id][this.messageIndex];
        // 将message各个属性赋值到列表中对应数据中
        Object.keys(data).forEach((key) => {
          const value = data[key];
          if (key.indexOf(".") < 0) {
            if (message[key] != value) {
              dataChanged = true;
              message[key] = value;
            }
          } else {
            const [parentKey, childKey] = key.split(".");
            if (message.msgs.msgs[parentKey][childKey] != value) {
              dataChanged = true;
              message.msgs.msgs[parentKey][childKey] = value;
            }
          }
        });
      } catch (error) {}
      if (dataChanged) {
        this.$forceUpdate();
      }
    },
    isSendSucceed() {
      return this.message.status == "send_succeed";
    },
    isSendFailed() {
      return this.message.status == "send_failed";
    },
    isSendGoing() {
      return this.message.status == "send_going";
    },
    isMechatWarning() {
      return this.message.meChatWarning;
    },
    //是否是自己发送的消息 id 新消息的 formUser.id
    isSelfMessage(id) {
      return this.control.user.id == id || this.message.fromUser.selfId;
    },
    _findContextmenuIndexByName(name) {
      return this.contextmenu.findIndex((item) => item.name == name);
    },
    _handleAvatarClick() {
      if (this.message.fromUser.id == 2) {
        return;
      }
      let userId = this.message.fromUser.id;
      if (this.message.fromUser.isGroup && this.message.sourceSender) {
        userId = this.message.sourceSender;
      }
      this.jumpPage({
        name: "personalCenter",
        params: { id: userId },
      });
    },
    // 右键出现操作菜单前，判断需求
    _handleContextmenuShow() {
      if (Date.now() - this.message.sendTime > 1000 * 60 * 2) {
        const index = this._findContextmenuIndexByName("recall");
        if (index !== -1) this.contextmenu.splice(index, 1);
      }
      if (this.isSendFailed()) {
        this.contextmenu = [];
      }
    },
    _parseTime(str) {
      if (!str) return;
      return parseTime(str);
    },
    _simpleParseTime(str) {
      if (!str) return;
      return parseTime(str, "{h}:{i}:{s}");
    },
    //格式化消息时间
    _formatTime: formatTime,

    _commonHandleContentClick(e) {
      this.handleContentClick && this.handleContentClick(e);
      //公共处理
      //this.control.$emit("message-click", this.message);
    },
    handleRecall() {
      if (this.message.onlineurl) {
        this.message.url = this.message.onlineurl;
      }
      this.control.$emit("recall", this.message, this.control.currentContact);
    },
    updataTranslageMsgGoing(index) {
      this.message.msgs.msgs[index].updataisGoing = true;
    },
    updataTranslateMsgFlish(index) {
      this.message.msgs.msgs[index].updataisGoing = false;
    },
    handleRelay() {
      // 分享消息转发，防止取消红字样式影响本地聊天记录，赋值给新变量
      let shareMessage = this.message;
      // 取消转发高亮
      Object.keys(shareMessage).forEach((key) => {
        if (typeof shareMessage[key] === "string") {
          shareMessage[key] = shareMessage[key].replace(
            '<font color="red">',
            ""
          );
          shareMessage[key] = shareMessage[key].replace("</font>", "");
        }
      });
      if (shareMessage.type == "notice") return;
      if (shareMessage.type == "text" && shareMessage.subType !== "link") {
        // shareMessage.text = this.$emoReplaceToImage(shareMessage.text)
        //   .replace(/&nbsp;/g, "")
        //   .replace(/<\/?.+?\/?>/g, "");
      }
      if (shareMessage.subType == "link") {
        let newmessage = {
          header:
            typeof shareMessage.catchMsg.header == "string"
              ? JSON.parse(shareMessage.catchMsg.header)
              : shareMessage.catchMsg.header,
          content:
            typeof shareMessage.catchMsg.content == "string"
              ? JSON.parse(shareMessage.catchMsg.content)
              : shareMessage.catchMsg.content,
          type: "link",
        };
        // // 缺少消息结构体，待后端新增
        this.shareForMelinked(newmessage);
      } else {
        if (shareMessage.onlineurl) {
          shareMessage.url = shareMessage.onlineurl;
        }
        this.control.openReplyDialog(shareMessage);
      }
    },
    // 发送邮件
    handleEmail() {
      this.$store.commit("setOpenEmailSendView", {
        open: true,
        type: "send",
        emailContent: this.message.text,
      });
    },
    shareForMelinked(shareData) {
      // 重置分享信息
      let sharingDataInfo = {
        // 分享的标题
        title: shareData.content.title,
        // 分享展示图片
        picurl: shareData.content.picurl,
        // 分享展示内容
        briefInfo: shareData.content.briefInfo,
        // 分享的类型（个人：people；群组：group；工作：job；帖子：service；公司：company）
        serviceType: "service",
        // 分享对象的唯一ID
        uuid: shareData.content.uuid,
      };
      this.$store.dispatch("commitOpenSharePopup", sharingDataInfo);
    },
    emitTranslateChange(data) {
      this.control._changeTranslateMsg(data);
    },
    tryAlignAuto() {
      console.log(this.message, "/try align");
    },
    quote() {
      const {
        text,
        type,
        subType,
        catchMsg,
        fromUser,
        fileName,
        urlInfos,
        id,
        duration,
      } = this.message;
      let catchMsgContent;
      if (catchMsg && catchMsg.content) {
        catchMsgContent =
          typeof catchMsg.content === "string"
            ? JSON.parse(catchMsg.content)
            : catchMsg.content;
      } else {
        catchMsgContent = catchMsg;
      }
      // 名字超過10個字符，顯示...
      if (fromUser.displayName && fromUser.displayName.length > 10) {
        fromUser.displayName = fromUser.displayName.slice(0, 10) + "..";
      }
      const currentContactId = this.control.currentContact.id;
      const quoteMessageIds = this.$store.state.imStore.quoteMessageIds;
      this.$store.commit("setQuoteMessageIds", {
        ...quoteMessageIds,
        [currentContactId]: id,
      });
      let innerWrap = document.querySelector(".inner-wrap");
      let wrap = document.querySelector(".imelink-editor__textarea");
      if (innerWrap) {
        wrap.removeChild(innerWrap);
      }
      const element = document.createElement("div");
      element.className = "inner-wrap";
      element.contentEditable = "false";
      if (type === "text" && typeof text === "string") {
        element.className = "inner-wrap text-wrap";
        element.innerHTML = `<div class="text-wrap">${
          fromUser.displayName
        }：${this.$emoReplaceToImage(
          text.replace(/\n/g, "<br>")
        )} </div> <span onclick="_cancelQuote(this)" class="el-icon-error cancel-quote"></span>`;
      }
      if (type === "file") {
        element.innerHTML = `<span class="formName">${fromUser.displayName}</span>：<i class="iconfont_Me icon-Frame-3" style="font-size:14px;color:#aaa;margin-right:1px;margin-top:-2px"></i> <span class="text-wrap">${fileName}</span> <span onclick="_cancelQuote()" class="el-icon-error cancel-quote"></span> `;
      }
      if (type === "image") {
        element.innerHTML = `<span class="formName">${fromUser.displayName}</span>：<i class="iconfont_Me icon-Frame-6" style="font-size:14px"></i>  <span onclick="_cancelQuote()" class="el-icon-error cancel-quote"></span>`;
      }
      if (type === "video") {
        element.innerHTML = `<span class="formName">${fromUser.displayName}</span>：<i class="iconfont_Me icon-Frame-5" style="font-size:14px"></i> <span onclick="_cancelQuote()" class="el-icon-error cancel-quote"></span>`;
      }
      if (type === "webUrl") {
        element.innerHTML = `<div class="text-wrap"> <span class="formName">${fromUser.displayName}</span>：<i class="iconfont_Me icon-Frame-4" style="font-size:14px;margin-right:2px"></i><span class="url-title">${urlInfos.title}</span></div>  <span onclick="_cancelQuote()" class="el-icon-error cancel-quote"></span>`;
      }
      if (type === "voice") {
        element.innerHTML = `<span class="formName">${fromUser.displayName}</span>：<div class="vioce-wrap"><i class="qu-voice__icon el-icon--play"></i></div> <span>${duration}"</span><span onclick="_cancelQuote()" class="el-icon-error cancel-quote"></span>`;
      }
      // 分享的名片类型
      if (
        type === "text" &&
        subType === "link" &&
        catchMsgContent.serviceType === "people"
      ) {
        element.innerHTML = `<div class="text-wrap"><span class="formName">${fromUser.displayName}</span> ：<i class="iconfont_Me icon-Frame-1" style="font-size:14px;margin-right:2px"></i><span>${catchMsgContent.title}</span><div><span onclick="_cancelQuote()" class="el-icon-error cancel-quote"></span>`;
      }
      // 分享的群类型
      if (
        type === "text" &&
        subType === "link" &&
        catchMsgContent.serviceType === "group"
      ) {
        element.innerHTML = `<div class="text-wrap"><span class="formName"> ${fromUser.displayName}</span>：<i class="iconfont_Me icon-Frame-2" style="font-size:14px;margin-right:2px"></i>${catchMsgContent.title}</span> </div> <span onclick="_cancelQuote()" class="el-icon-error cancel-quote"></span>`;
      }
      // 分享的帖子类型
      if (
        type === "text" &&
        subType === "link" &&
        catchMsgContent.serviceType === "service"
      ) {
        element.innerHTML = `<div class="text-wrap"><span class="formName">${
          fromUser.displayName
        }</span>：<i class="iconfont_Me icon-Frame1" style="font-size:14px;margin-right:2px"></i><span>${
          catchMsgContent.title
            ? catchMsgContent.title
            : catchMsgContent.briefInfo
        }</span></div><span onclick="_cancelQuote()" class="el-icon-error cancel-quote"></span>`;
      }
      const notEmpty = !!wrap.innerHTML;
      if (!wrap.innerHTML) {
        wrap.innerHTML = "<br>";
      }
      sessionStorage.setItem("inner-wrap", JSON.stringify(element.outerHTML));
      wrap.appendChild(element);
      var sel,
        el = document.querySelector(".imelink-editor__textarea");
      if (notEmpty) {
        sel = window.getSelection();
        sel.collapse(el, 1);
      }
      el.focus();
    },
  },
};
