var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "typing" }, [
    _c("span", {
      style: { "white-space": "pre-line" },
      domProps: { innerHTML: _vm._s(_vm.renderCodeBlock(_vm.displayText)) }
    }),
    _vm.isTyping
      ? _c("span", {
          staticClass: "cursor",
          class: { blink: _vm.isCursorVisible }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }